export const GET_GALLERYS_STATED = "GET_GALLERYS_STATED";
export const GET_GALLERYS = "GET_GALLERYS";
export const GET_GALLERYS_ENDED = "GET_GALLERYS_ENDED";
export const ADD_GALLERY_STATED = "ADD_GALLERY_STARTED";
export const ADD_GALLERY = "ADD_GALLERY";
export const ADD_GALLERY_ENDED = "ADD_GALLERY_ENDED";
export const EDIT_GALLERY_STATED = "EDIT_GALLERY_STATED";
export const EDIT_GALLERY = "EDIT_GALLERY";
export const EDIT_GALLERY_ENDED = "EDIT_GALLERY_ENDED";
export const GET_GALLERY = "GET_GALLERY";
export const GET_GALLERY_STATED = "GET_GALLERY_STATED";
export const GET_GALLERY_ENDED = "GET_GALLERY_ENDED";
export const RESET_GALLERY = "RESET_GALLERY";
export const ERROR_GALLERY = "ERROR_GALLERY";
export const GET_ALL_GALLERYS_STATED = "GET_ALL_GALLERYS_STATED";
export const GET_ALL_GALLERYS = "GET_ALL_GALLERYS";
export const GET_ALL_GALLERYS_ENDED = "GET_ALL_GALLERYS_ENDED";
