import {
  GET_GALLERYS_STATED,
  GET_GALLERYS,
  GET_GALLERYS_ENDED,
  ADD_GALLERY_STATED,
  ADD_GALLERY,
  ADD_GALLERY_ENDED,
  EDIT_GALLERY_STATED,
  EDIT_GALLERY,
  EDIT_GALLERY_ENDED,
  GET_GALLERY_STATED,
  GET_GALLERY,
  GET_GALLERY_ENDED,
  GET_ALL_GALLERYS_STATED,
  GET_ALL_GALLERYS,
  GET_ALL_GALLERYS_ENDED
} from "../types/gallery_type";

const initialState = {
  gallerys_loading: true,
  gallerys: null,
  page: null,
  pages: null,
  total_gallerys: 0,

  gallery: null,
  gallery_loading: null,

  loading: true,

  gallery_message: null,
  all_gallerys: null,
  all_gallerys_loading: null,
  add_gallery_loading: true,
  edit_gallery_loading: true
};

export const gallery_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_GALLERYS_STATED:
      return {
        ...state,
        gallerys: null,
        pages: null,
        page: null,
        total_gallerys: 0,
        gallerys_loading: true
      };
    case GET_GALLERYS:
      return {
        ...state,
        gallerys: payload.gallerys,
        pages: payload.pages,
        page: payload.page,
        total_gallerys: payload.count
      };
    case GET_GALLERYS_ENDED:
      return {
        ...state,
        gallerys_loading: false
      };
    case GET_ALL_GALLERYS_STATED:
      return {
        ...state,
        all_gallerys_loading: true,
        all_gallerys: null
      };
    case GET_ALL_GALLERYS:
      return {
        ...state,
        all_gallerys: payload
      };
    case GET_ALL_GALLERYS_ENDED:
      return {
        ...state,
        all_gallerys_loading: false
      };

    case ADD_GALLERY_STATED:
      return {
        ...state,
        gallery_message: null,
        add_gallery_loading: true
      };
    case ADD_GALLERY:
      return {
        ...state,
        gallery_message: payload
      };
    case ADD_GALLERY_ENDED:
      return {
        ...state,
        add_gallery_loading: false
      };
    case GET_GALLERY_STATED:
      return {
        ...state,
        gallery: null,
        gallery_loading: true
      };
    case GET_GALLERY:
      return {
        ...state,
        gallery: payload
      };
    case GET_GALLERY_ENDED:
      return {
        ...state,
        gallery_loading: false
      };
    case EDIT_GALLERY_STATED:
      return {
        ...state,
        gallery_message: null,
        edit_gallery_loading: true
      };
    case EDIT_GALLERY:
      return {
        ...state,
        gallery_message: payload
      };
    case EDIT_GALLERY_ENDED:
      return {
        ...state,
        edit_gallery_loading: false
      };

    default:
      return state;
  }
};
