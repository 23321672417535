import { combineReducers } from "redux";
import alert from "./alert_reducer";
import auth from "./auth_reducer";
import { appointment_reducer } from "./appointment_reducer";
import { banner_reducer } from "./banner_reducer";
import { blog_reducer } from "./blog_reducer";
import { category_reducer } from "./category_reducer";
import { contact_reducer } from "./contact_reducer";
import { faq_reducer } from "./faq_reducer";
import { gallery_reducer } from "./gallery_reducer";
import { newsletter_reducer } from "./newsletter_reducer";
import { policy_reducer } from "./policy_reducer";
import { product_reducer } from "./product_reducer";
import { service_reducer } from "./service_reducer";
import { setting_reducer } from "./setting_reducer";
import { testimonial_reducer } from "./testimonial_reducer";
import { order_reducer } from "./order_reducer";

export default combineReducers({
  alert,
  auth,
  appointment: appointment_reducer,
  banner: banner_reducer,
  blog: blog_reducer,
  category: category_reducer,
  contact: contact_reducer,
  faq: faq_reducer,
  gallery: gallery_reducer,
  newsletter: newsletter_reducer,
  policy: policy_reducer,
  product: product_reducer,
  service: service_reducer,
  setting: setting_reducer,
  testimonial: testimonial_reducer,
  order: order_reducer,
});
