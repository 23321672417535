import {
  GET_SETTINGS_STATED,
  GET_SETTINGS,
  GET_SETTINGS_ENDED,
  ADD_SETTING_STATED,
  ADD_SETTING,
  ADD_SETTING_ENDED,
  EDIT_SETTING_STATED,
  EDIT_SETTING,
  EDIT_SETTING_ENDED,
  GET_SETTING_STATED,
  GET_SETTING,
  GET_SETTING_ENDED,
  GET_ALL_SETTINGS_STATED,
  GET_ALL_SETTINGS,
  GET_ALL_SETTINGS_ENDED
} from "../types/setting_type";

const initialState = {
  settings_loading: true,
  settings: null,
  page: null,
  pages: null,
  total_settings: 0,

  setting: null,
  setting_loading: null,

  loading: true,

  setting_message: null,
  all_settings: null,
  all_settings_loading: null,
  add_setting_loading: true,
  edit_setting_loading: true
};

export const setting_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SETTINGS_STATED:
      return {
        ...state,
        settings: null,
        pages: null,
        page: null,
        total_settings: 0,
        settings_loading: true
      };
    case GET_SETTINGS:
      return {
        ...state,
        settings: payload.settings,
        pages: payload.pages,
        page: payload.page,
        total_settings: payload.count
      };
    case GET_SETTINGS_ENDED:
      return {
        ...state,
        settings_loading: false
      };
    case GET_ALL_SETTINGS_STATED:
      return {
        ...state,
        all_settings_loading: true,
        all_settings: null
      };
    case GET_ALL_SETTINGS:
      return {
        ...state,
        all_settings: payload
      };
    case GET_ALL_SETTINGS_ENDED:
      return {
        ...state,
        all_settings_loading: false
      };

    case ADD_SETTING_STATED:
      return {
        ...state,
        setting_message: null,
        add_setting_loading: true
      };
    case ADD_SETTING:
      return {
        ...state,
        setting_message: payload
      };
    case ADD_SETTING_ENDED:
      return {
        ...state,
        add_setting_loading: false
      };
    case GET_SETTING_STATED:
      return {
        ...state,
        setting: null,
        setting_loading: true
      };
    case GET_SETTING:
      return {
        ...state,
        setting: payload
      };
    case GET_SETTING_ENDED:
      return {
        ...state,
        setting_loading: false
      };
    case EDIT_SETTING_STATED:
      return {
        ...state,
        setting_message: null,
        edit_setting_loading: true
      };
    case EDIT_SETTING:
      return {
        ...state,
        setting_message: payload
      };
    case EDIT_SETTING_ENDED:
      return {
        ...state,
        edit_setting_loading: false
      };

    default:
      return state;
  }
};
