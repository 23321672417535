export const GET_SETTINGS_STATED = "GET_SETTINGS_STATED";
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_ENDED = "GET_SETTINGS_ENDED";
export const ADD_SETTING_STATED = "ADD_SETTING_STARTED";
export const ADD_SETTING = "ADD_SETTING";
export const ADD_SETTING_ENDED = "ADD_SETTING_ENDED";
export const EDIT_SETTING_STATED = "EDIT_SETTING_STATED";
export const EDIT_SETTING = "EDIT_SETTING";
export const EDIT_SETTING_ENDED = "EDIT_SETTING_ENDED";
export const GET_SETTING = "GET_SETTING";
export const GET_SETTING_STATED = "GET_SETTING_STATED";
export const GET_SETTING_ENDED = "GET_SETTING_ENDED";
export const RESET_SETTING = "RESET_SETTING";
export const ERROR_SETTING = "ERROR_SETTING";
export const GET_ALL_SETTINGS_STATED = "GET_ALL_SETTINGS_STATED";
export const GET_ALL_SETTINGS = "GET_ALL_SETTINGS";
export const GET_ALL_SETTINGS_ENDED = "GET_ALL_SETTINGS_ENDED";
