import {
  GET_POLICYS_STATED,
  GET_POLICYS,
  GET_POLICYS_ENDED,
  ADD_POLICY_STATED,
  ADD_POLICY,
  ADD_POLICY_ENDED,
  EDIT_POLICY_STATED,
  EDIT_POLICY,
  EDIT_POLICY_ENDED,
  GET_POLICY_STATED,
  GET_POLICY,
  GET_POLICY_ENDED,
  GET_ALL_POLICYS_STATED,
  GET_ALL_POLICYS,
  GET_ALL_POLICYS_ENDED
} from "../types/policy_type";

const initialState = {
  policys_loading: true,
  policys: null,
  page: null,
  pages: null,
  total_policys: 0,

  policy: null,
  policy_loading: null,

  loading: true,

  policy_message: null,
  all_policys: null,
  all_policys_loading: null,
  add_policy_loading: true,
  edit_policy_loading: true
};

export const policy_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_POLICYS_STATED:
      return {
        ...state,
        policys: null,
        pages: null,
        page: null,
        total_policys: 0,
        policys_loading: true
      };
    case GET_POLICYS:
      return {
        ...state,
        policys: payload.policys,
        pages: payload.pages,
        page: payload.page,
        total_policys: payload.count
      };
    case GET_POLICYS_ENDED:
      return {
        ...state,
        policys_loading: false
      };
    case GET_ALL_POLICYS_STATED:
      return {
        ...state,
        all_policys_loading: true,
        all_policys: null
      };
    case GET_ALL_POLICYS:
      return {
        ...state,
        all_policys: payload
      };
    case GET_ALL_POLICYS_ENDED:
      return {
        ...state,
        all_policys_loading: false
      };

    case ADD_POLICY_STATED:
      return {
        ...state,
        policy_message: null,
        add_policy_loading: true
      };
    case ADD_POLICY:
      return {
        ...state,
        policy_message: payload
      };
    case ADD_POLICY_ENDED:
      return {
        ...state,
        add_policy_loading: false
      };
    case GET_POLICY_STATED:
      return {
        ...state,
        policy: null,
        policy_loading: true
      };
    case GET_POLICY:
      return {
        ...state,
        policy: payload
      };
    case GET_POLICY_ENDED:
      return {
        ...state,
        policy_loading: false
      };
    case EDIT_POLICY_STATED:
      return {
        ...state,
        policy_message: null,
        edit_policy_loading: true
      };
    case EDIT_POLICY:
      return {
        ...state,
        policy_message: payload
      };
    case EDIT_POLICY_ENDED:
      return {
        ...state,
        edit_policy_loading: false
      };

    default:
      return state;
  }
};
