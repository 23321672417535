export const GET_POLICYS_STATED = "GET_POLICYS_STATED";
export const GET_POLICYS = "GET_POLICYS";
export const GET_POLICYS_ENDED = "GET_POLICYS_ENDED";
export const ADD_POLICY_STATED = "ADD_POLICY_STARTED";
export const ADD_POLICY = "ADD_POLICY";
export const ADD_POLICY_ENDED = "ADD_POLICY_ENDED";
export const EDIT_POLICY_STATED = "EDIT_POLICY_STATED";
export const EDIT_POLICY = "EDIT_POLICY";
export const EDIT_POLICY_ENDED = "EDIT_POLICY_ENDED";
export const GET_POLICY = "GET_POLICY";
export const GET_POLICY_STATED = "GET_POLICY_STATED";
export const GET_POLICY_ENDED = "GET_POLICY_ENDED";
export const RESET_POLICY = "RESET_POLICY";
export const ERROR_POLICY = "ERROR_POLICY";
export const GET_ALL_POLICYS_STATED = "GET_ALL_POLICYS_STATED";
export const GET_ALL_POLICYS = "GET_ALL_POLICYS";
export const GET_ALL_POLICYS_ENDED = "GET_ALL_POLICYS_ENDED";
